import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'

const aboutText = [
    { text: `In the complex landscape of legal challenges, having a trusted ally is essential. At BAS Law Group, we bring expertise, dedication, and a results-driven approach to your side.` },
    { text: `Our commitment is to navigate the intricacies of the law on your behalf, offering peace of mind and strategic solutions. When you choose us, you gain a reliable partner dedicated to securing your legal interests and achieving favorable outcomes. Your success is our priority – let us be the solution to your legal needs.` },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Legal Matters in Relationships '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'Legal Remedies for Harm and Compensation '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'Legal Framework for Commercial Transactions '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'Legal System and Offenses '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'Legal Principles in Educational Settings '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'Legal Aspects of Property Transactions '
    },
]

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'About us' }
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Why You Need Us"
                subTitle="We Are Expert"
                images={about}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <FooterArea />
        </Fragment>
    )
}
export default AboutPage