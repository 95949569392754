import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const Booking = () => {

  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(new Date());

  const today = new Date();
  const availableSpecialists = ['Specialist 1', 'Specialist 2', 'Specialist 3'];
  const availableTimeSlots = [
    '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM'
  ];

  const handleSelectSpecialist = (specialist) => {
    setSelectedSpecialist(specialist);
  };

  const handleSelectStartTime = (time) => {
    const newTime = new Date(selectedStartTime);
    const [hours, minutes] = time.split(':');
    newTime.setHours(parseInt(hours, 10));
    newTime.setMinutes(parseInt(minutes, 10));
    setSelectedStartTime(newTime);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartTime(date);
  };

  const handleSubmit = () => {
    const endTime = new Date(selectedStartTime.getTime() + 60 * 60 * 1000); // Calculate end time 1 hour after start time
    console.log('Selected start time:', selectedStartTime);
    console.log('Selected end time:', endTime);
  };

  return (
    <div className="container">
      <form className='bookingForm'>
        <div className="row">
          <div className="col-sm-6">
            <div className="formInput">
              <label>Select a Specialist:</label>
              <select className="form-control" onChange={(e) => handleSelectSpecialist(e.target.value)}>
                <option value="">Select Specialist</option>
                {availableSpecialists.map((specialist) => (
                  <option key={specialist} value={specialist}>
                    {specialist}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="formInput">
              <label>Select Start Date:</label>
              <ReactDatePicker
                className="form-control"
                selected={selectedStartTime}
                onChange={handleStartDateChange}
                minDate={today} // Disable past days
                dateFormat="dd/MM/yyyy" />
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <label>Select a Start Time:</label>
              <div className="time-slots">
                {availableTimeSlots.map((time) => (
                  <div key={time} onClick={() => handleSelectStartTime(time)} className="time-slot-button">
                    {time}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {selectedStartTime && (
            <div className="col-12">
              <p>
                <strong>Appointment Details:</strong><br />
                <strong>Date:</strong> {selectedStartTime.toLocaleDateString()}<br />
                <strong>Time:</strong> {selectedStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} to {new Date(selectedStartTime.getTime() + 60 * 60 * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}<br />
                <strong>Specialist:</strong> {selectedSpecialist}<br />
              </p>
            </div>
          )}
          <div className="col-sm-6">
            <div className="formInput">
              <label>Email:</label>
              <input type="email" className="form-control" />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="formInput">
              <label>Name:</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="formInput">
              <label>Phone Number:</label>
              <input type="tel" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <button className="booking-button" onClick={handleSubmit}>Book Appointment</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Booking