import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import breadcumb from '../../../images/breadcumb/1.jpg'


const breadcumbMenu = [
  { name: 'Home', route: '/' },
  { name: 'Services' }
]

const services = [
  {
    id: "1",
    icon: 'flaticon-parents',
    title: 'Family Law',
    content: 'Legal Matters in Relationships '
  },
  {
    id: "2",
    icon: 'flaticon-wounded',
    title: 'Personal Injury',
    content: 'Legal Remedies for Harm and Compensation '
  },
  {
    id: "3",
    icon: 'flaticon-employee',
    title: 'Business Law',
    content: 'Legal Framework for Commercial Transactions '
  },
  {
    id: "4",
    icon: 'flaticon-thief',
    title: 'Criminal Law',
    content: 'Legal System and Offenses '
  },
  {
    id: "5",
    icon: 'flaticon-university-graduate-hat',
    title: 'Education Law',
    content: 'Legal Principles in Educational Settings '
  },
  {
    id: "6",
    icon: 'flaticon-house',
    title: 'Real Estate Law',
    content: 'Legal Aspects of Property Transactions '
  },
]

const ServicesPage = () => {
  return (
    <>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Services"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <div>
        welcome to services page
      </div>
      <FooterArea />
    </>
  )
}
export default ServicesPage