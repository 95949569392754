// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import ruTranslation from './translations/ru.json';
import hyTranslation from './translations/hy.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    debug: true,
    resources: {
      en: {
        translation: enTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
      hy: {
        translation: hyTranslation,
      },
    },
  });

export default i18n;
