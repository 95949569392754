import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import signature from '../../../images/about/1.png'

// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'


// const { t } = useTranslation()

const aboutText = [
    { text: 'At BAS Law Group, we pride ourselves on delivering comprehensive legal solutions with a personalized touch. Our dedicated team of experienced attorneys is committed to providing strategic guidance and effective representation. With a focus on integrity and client satisfaction, we navigate legal complexities with precision, ensuring that our clients receive the highest standard of service.' },
    { text: ' Discover the difference of working with a legal partner who values your unique needs and strives for excellence in every aspect of the law. Welcome to BAS Law Group, where your legal success is our priority.' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: t("hero_sliders_title"),
        subTitle: 'As Like A Friend.',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'We Fight For Your Justice',
        subTitle: 'As Like A Friend.',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Legal Dynamics in Relationships, Custody, and Support'
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'Legal Avenues for Compensation and Redress'
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'Navigating Legal Structures, Contracts, and Commercial Transactions'
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: ' Exploring the Legal Codes, Offenses, and Justice System'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'Legal Framework for Educational Rights and Responsibilities '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'Understanding and Navigating Legalities in Property Transactions and Ownership'
    },
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider
                sliders={heroSliders}
                className="heroSliderArea" />
            <Service className="serviceArea" />
            <About
                className="aboutArea"
                title="About Us"
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Portfolio
                className="portfolioArea"
                title="Our Resent Case Studies"
                subTitle="Here Our Best Work"
                portfolioItem={portfolioItem}
            />
            <Testmonial
                className="testmonialArea"
            />
            <ContactArea
                className="contactArea"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <CounterArea
                className="counterArea"
            />

            <FooterArea />
        </Fragment>
    )
}
export default HomePageOne