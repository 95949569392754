import React, { useState } from 'react';
import logo from '../../images/logo/logo.png';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

const HeaderBottom = (props) => {
    const [search, setSearch] = useState('');
    const [responsive, setResponsive] = useState(false);
    const [trigger, setTrigger] = useState(false);

    const { t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        console.log(search);
    };

    const clickHandler = () => {
        setTrigger(!trigger);
    };

    const responsiveHandler = () => {
        setResponsive(!responsive);
    };

    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <Navbar expand="lg">
                        <Container>
                            <Navbar.Brand href="/" className="custom-font-color"><img style={{ width: "250px", marginRight: "50px" }} src={logo} alt="logo" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarNavDropdown" />
                            <Navbar.Collapse id="navbarNavDropdown" >
                                <Nav style={{ width: "100%", justifyContent: "space-between" }}>
                                    <Nav.Link href="/" className="custom-font-color">{t("home")}</Nav.Link>
                                    <NavDropdown title={<span className="custom-font-color">{t("services")}</span>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/service1">{t("customs_and_tax_concerns")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service2">{t("problems_with_trademarks")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service3">{t("problems_with_debt_collection")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service4">{t("financial_conflicts")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service5">{t("concerns_about_insurance")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service6">{t("problems_with_divorce")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service7">{t("concerns_with_copyright")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service8">{t("workplace_conflicts")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service9">{t("international_legal_matters")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service10">{t("protecting_honor_and_dignity")}</NavDropdown.Item>
                                        <NavDropdown.Item href="/service11">{t("issues_with_transportation_and_supplies")}</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href="/about" className="custom-font-color">{t("about")}</Nav.Link>
                                    <Nav.Link href="/practice" className="custom-font-color">{t("practice")}</Nav.Link>
                                    <Nav.Link href="/attorneys" className="custom-font-color">{t("attorneys")}</Nav.Link>
                                    <Nav.Link href="/contact" className="custom-font-color">{t("contact")}</Nav.Link>
                                    <NavDropdown title={<span className="custom-font-color">{t("current_language")}</span>} id="basic-nav-dropdown">
                                        <NavDropdown.Item onClick={() => changeLanguage('en')}>English 🇺🇸</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => changeLanguage('ru')}>Русский 🇷🇺</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => changeLanguage('hy')}>հայերեն 🇦🇲</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </div>
    );
};

export default HeaderBottom;