import React, { Fragment } from 'react';
import Booking from '../../../components/Booking/Booking';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import HeaderBottom from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import Service from '../../../components/Service';
import SectionTitle from '../../../components/Title';
import breadcumb from '../../../images/breadcumb/1.jpg'


const BookingPage = () => {

  const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Booking a Consultation' }
  ];

  return (
    <Fragment>

      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>

      <Breadcumb
        className="breadcumbArea"
        title="Booking"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <Service className="serviceArea mt-0" />

      <SectionTitle title="Book a Consultation" />

      <Booking />

      <FooterArea />
    </Fragment>
  );
};

export default BookingPage;
