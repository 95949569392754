import React, { Fragment, Component } from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import HomePageOne from '../Pages/HomePageOne'
import AboutPage from '../Pages/AboutPage'
import PracticePage from '../Pages/PracticePage'
import PortfolioPage from '../Pages/PortfolioPage'
import SinglePortfolioPage from '../Pages/SinglePortfolioPage'
import TeamPage from '../Pages/TeamPage'
import SingleTeamPage from '../Pages/SingleTeamPage'
import ContactPage from '../Pages/ContactPage'
import './App.css';
import Services from '../Pages/ServicesPage';
import BookingPage from '../Pages/BookingPage/BookingPage';

class App extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <ToastContainer autoClose={2500} position="top-center" />
                    <Routes>
                        <Route exact path="/" element={<HomePageOne />} />
                        <Route exact path="services" element={<Services />} />
                        <Route exact path="about" element={<AboutPage />} />
                        <Route exact path="practice" element={<PracticePage />} />
                        <Route exact path="case-stadies" element={<PortfolioPage />} />
                        <Route exact path="case-stadies-details" element={<SinglePortfolioPage />} />
                        <Route exact path="attorneys" element={<TeamPage />} />
                        <Route exact path="attorneys-single" element={<SingleTeamPage />} />
                        <Route exact path="contact" element={<ContactPage />} />
                        <Route exact path="bookingpage" element={<BookingPage />} />
                    </Routes>
                </BrowserRouter>
            </Fragment >
        );
    }
}

export default App;
