import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss'
const HeaderTop = props => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <>
            {/* <div className={props.className}>
                <div className="container">
                    <div className="headerTopMainWrapper">
                        <div className="headertop">
                            <div className="">
                                <ul className="d-flex accountLoginArea">
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i>{t("address")}</li>
                                </ul>
                            </div>
                            <div className="">
                                <ul className="headerContact">
                                    <li><i className="fa fa-phone"></i>+374 77 28 76 75</li>
                                </ul>
                            </div>

                            <div className="">
                                <div className="btnStyle btnStyle2 text-right" onClick={() => {
                                    navigate("/bookingpage")
                                }}>
                                    <a style={{ width: "300px" }} href="#">{t("book_consultation")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div > */}
            <div className="headerTopMainWrapper">
                <div className='container'>
                    <div className="headertop">
                        <ul>
                            <li><i className="fa fa-map-marker" aria-hidden="true"></i>{t("address")}</li>
                            <li><i className="fa fa-phone"></i>+374 77 28 76 75</li>
                        </ul>
                        <button onClick={() => {
                            navigate("/bookingpage")
                        }}>{t("book_consultation")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HeaderTop